import React from 'react';
import { Jumbotron, Container, Card } from 'reactstrap';
import TicketNumbers from './TicketNumbers';
import lottoMaxLogo from './lmax-2.0-logo-small.png';
import extraLogo from './logo-lotto-max-extra.svg';

const LastDraw = (props) => {
  return (
    <div>
      <Jumbotron className="bg-lott-max">
        <Container className="text-center">
          <h1 className="display-4 text-white">
            <img src={lottoMaxLogo} alt="logo" className="vertical-bottom" />
            Winning Numbers!
          </h1>
        </Container>
        <hr className="my-0" />
        <Card body>
          <Container className="text-center mt-2">
            <p className="lead">LOTTO MAX RESULTS: <span className="text-uppercase"><strong>{props.draw.date}</strong></span></p>
            <p className="lead"><small>BONUS: <span className="bonus">{props.draw.bonus}</span></small></p>
            <TicketNumbers numbers={props.draw.numbers} />
          </Container>
          <Container className="text-center mt-2">
            <h5 className="display-6">
              <img src={extraLogo} alt="logo" className="vertical-bottom" width="54px" height="24px" />
              &nbsp;Winning Numbers
            </h5>
          </Container>
          <Container className="text-center">
            <TicketNumbers numbers={props.draw.extra} />
          </Container>
        </Card>
        <div className="d-flex justify-content-center align-items-center mt-2">
          <small className="text-white">
            <b>Total Earnings: ${props.totalEarnings}.00</b>
          </small>
        </div>
      </Jumbotron>
    </div>
  );
};

export default LastDraw;