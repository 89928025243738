import React from 'react';
import { Navbar } from 'reactstrap';
import LastDraw from './LastDraw';
import Ticket from './Ticket' ;
import { Container, Row, Col } from 'reactstrap';
import data from './data/data.json';

const tickets = [...data].reverse();
const recentTicket = [...tickets].shift();
const currentDraw = recentTicket.draw.numbers.length > 0 ? recentTicket : (recentTicket.draw.numbers.length > 0 ? [...tickets][1] : [...tickets][3]);

let earning = 0;

const countTicketEarnings = (ticketsData) => {
   for (let ticketData of ticketsData) {
    for (let ticket of  ticketData.tickets) {
      let matchCount = 0;
      const bonus = ticket.numbers.indexOf(ticketData.draw.bonus) !== -1;

      ticket.numbers.map((number) => ticketData.draw.numbers.indexOf(number) !== -1 ? matchCount++ : matchCount);

      if (matchCount === 3 && bonus === true) {
        earning += 20;
      } else if (matchCount === 4) {
        earning += 20;
      }
    }

    for (let extra of ticketData.extras) {
      let matchExtraCount = 0;
      extra.numbers.map((number) => (ticketData.draw.extra.indexOf(number) !== -1 ? matchExtraCount++ : matchExtraCount));

      if (matchExtraCount === 1) {
        earning += 1;
      } else if (matchExtraCount === 2) {
        earning += 10;
      } else if (matchExtraCount === 3) {
        earning += 100;
      } else if (matchExtraCount === 4) {
        earning += 500000;
      }
    }
  }

  return earning;
}

const App = () => {
  return (
    <Container>
      <Navbar color="white" light expand="md">
      </Navbar>
      <Container>
        <LastDraw draw={currentDraw.draw} totalEarnings={countTicketEarnings(tickets)} />
        <Row>
          {tickets.map((d, key) =>
            <Col key={key} sm="6" className="mb-2">
              <Ticket
                title={d.draw.date}
                tickets={d.tickets}
                extras={d.extras}
                draw={d.draw}
                isOpen={(key > 1 && d.draw.numbers.length > 1) ? false : true} />
            </Col>
          )}
        </Row>
      </Container>
    </Container>
  );
}

export default App;