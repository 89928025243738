import React from 'react';
import { Container } from 'reactstrap';

const markNumberSelected = (numbers, number) => {
  if (typeof(numbers) != 'undefined') {
    return numbers.indexOf(number) !== -1 ? 'number-selected' : 'draw-number';
  } else {
    return 'draw-number';
  }
}

const TicketNumbers = (props) => {
  return (
    <Container className="mb-3 mt-3 d-flex justify-content-center align-items-center">
      <div>
        {props.numbers.map((number, key) =>
          <span key={key} className={markNumberSelected(props.draw, number) + ' mb-4 ml-1 mr-1'}><small>{number}</small></span>
        )}
      </div>
    </Container>
  );
};

export default TicketNumbers;