import React, { useState } from 'react';
import { Collapse, ListGroup, ListGroupItem, CardBody, Card } from 'reactstrap';
import TicketNumbers from './TicketNumbers';
import extraLogo from './logo-lotto-max-extra.svg';
import lottoMaxLogo from './lmax-2.0-logo-small.png';

let extraPrize = 0;
let lottoPrize = 0;
let freePlay = false;

const handleExtraPrizes = (draw, extras) => {
  let matchCount = 0;

  extras.map((extra) => (draw.indexOf(extra) !== -1 ? matchCount++ : matchCount));

  if (matchCount === 1) {
    extraPrize += 1;
  } else if (matchCount === 2) {
    extraPrize += 10;
  } else if (matchCount === 3) {
    extraPrize += 100;
  } else if (matchCount === 4) {
    extraPrize += 500000;
  }
}

const handleLottoPrizes = (draw, numbers) => {
  let matchCount = 0;
  const bonus = numbers.indexOf(draw.bonus) !== -1;

  numbers.map((number) => (draw.indexOf(number) !== -1 ? matchCount++ : matchCount));

  if (matchCount === 3) {
    freePlay = true;
  } else if (matchCount === 3 && bonus === true) {
    lottoPrize += 20;
  } else if (matchCount === 4) {
    lottoPrize += 20;
  } else if (matchCount > 4) {
    lottoPrize = 100;
  }
}

const Ticket = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const toggle = () => setIsOpen(!isOpen);

  extraPrize = 0;
  lottoPrize = 0;
  freePlay = false;

  return (
    <div>
      {props.tickets.map((ticket, key) =>
        handleLottoPrizes((props.draw.numbers.length > 0 ? props.draw.numbers : []), ticket.numbers)
      )}
      {props.extras.map((extra, key) =>
        handleExtraPrizes((props.draw.extra.length > 0 ? props.draw.extra : []), extra.numbers)
      )}
      <ListGroup>
        <ListGroupItem color={props.color} tag="button" action onClick={toggle}>
          <div className="d-flex justify-content-between">
            <span>
              <small>{props.title}</small>
              <br/>
              {(lottoPrize > 0 || extraPrize > 0 || freePlay === true ?
                <small className="text-success"><b>Winner</b></small>
                :
                null
              )}
            </span>
            <span>
              <small><b>Winning numbers</b></small>
              <br/>
              {(props.draw.numbers.length > 0 ?
                <small>{props.draw.numbers.join('-')} <small className="bonus">{props.draw.bonus}</small></small>
              :
                <small>Upcoming draws</small>
              )}
            </span>
          </div>
        </ListGroupItem>
      </ListGroup>
      <Collapse isOpen={isOpen}>
        <Card>
          <div className="text-center">
            <img src={lottoMaxLogo} alt="logo" className="vertical-bottom" height="48px" />
            {((lottoPrize > 0 && lottoPrize <= 20) ?
              <small className="text-success">&nbsp;&nbsp;<b>You won ${lottoPrize}.00{lottoPrize === 5 ? " (Free Play)" : null}</b></small>
              :
              null
            )}
            {((freePlay === true) ?
              <small className="text-success">&nbsp;&nbsp;<b>You won a Free Play</b></small>
              :
              null
            )}
          </div>
          <hr className="my-2" />
          <CardBody className="text-center">
            {props.tickets.map((ticket, key) =>
              <TicketNumbers key={key} numbers={ticket.numbers} draw={props.draw.numbers} />
            )}
          </CardBody>
          {(props.extras.length > 0 ?
            <>
              <hr className="my-2" />
              <div className="text-center">
                <img src={extraLogo} alt="logo" className="vertical-bottom" width="54px" height="24px" />
                {(extraPrize > 0 ?
                  <small className="text-success">&nbsp;&nbsp;<b>You won ${extraPrize}.00</b></small>
                :
                  null
                )}
              </div>
              <CardBody className="text-center">
                {props.extras.map((extra, key) =>
                  <TicketNumbers key={key} numbers={extra.numbers} draw={props.draw.extra} />
                )}
              </CardBody>
            </>
          :
            null
          )}
        </Card>
      </Collapse>
    </div>
  );
}

export default Ticket;